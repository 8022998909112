.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #768EFB;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  text-align: center;
  color: white;
}

.auth h1 {
  text-align: center;
  color: white;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: white;
  color: black;
  border-radius: 4px;
  border: 1px solid white;
  width: 85%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #7FE5EA;
  border: 1px solid #7FE5EA;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:active {
  opacity: .7;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #7FE5EA;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  opacity: .5;
}

@media screen and (max-width:480px) {
  .auth {
    width: 75%;
    padding: .5rem;
    margin-bottom: 5rem;
  }
}