/*segoe web font*/
body {
    font-family: "Segoe UI", Arial, sans-serif;
}
.header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100px;
}
.headerImage{
    background-image: url("../src/assets/images/Peak-Industry-Solutions-Logo-cropped.svg");
    height: 33.25rem;
    width: 11.25rem;
    margin-left: 5rem;
    margin-top: -3.6rem;
    position: absolute;
    background-repeat: no-repeat;
}
.logo{
    margin-left: 3rem;
}

.nav {
    grid-column-start: 3;
    display: flex;
    align-items: center;
    margin-left: 7rem;
}

.nav a {
    padding: .5rem;
    text-decoration: none;
    color: #768EFB;
}

.nav a:hover {
    opacity: .6;
}

.hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../src/assets/images/construction-workers2.jpg"), url("../src/assets/images/construction-workers.jpg");
    background-position: left, right;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    height: 450px;
    width: 100%;
}

.hero h2 {
    color: #768EFB;
}

.homepageContent{
    padding-top: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 5rem;
}
.homepageLeft h1 {
    color: #768EFB;
}
.homepageLeft{
    display: flex;
    flex-direction: column;
    width: 75%;
    grid-column-start: 1;
    margin-left: 5rem;
    align-items: center;
    margin-left: 12rem;
    margin: auto;
    
}
.homepageLeft h1{
    text-align: center;
}
.homepageLeft img{
   margin-left: 3.2rem;
}
.homepageRight{
    display: flex;
    flex-direction: column;
    background-color: #768EFB;
    grid-column-start: 2;
    margin-left: 5rem;
    align-items: center;
    border-radius: 15px;
    width: 75%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: 500px;
}

.homepageRight h1{
    text-align: center;
}

.blueButton{
    height: 4rem;
    width: 20rem;
    border-radius: 8px;
    background-color: #7FE5EA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.5rem;
    border: 1px solid #7FE5EA;
}
.hero button {
    height: 8rem;
    width: 20rem;
    border-radius: 8px;
    background-color: #768EFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 1.5rem;
    border: 1px solid #768EFB;
}
.hero button:active {
    
    box-shadow: none;
}
.hero button h1{
    color: white;
}

.blueButton:active {
    box-shadow: none;
    opacity: .7;
}

.competitiveJob{
    display: flex;
    flex-direction: column;
    height: 250px;
    align-items: center;
}

.skilledEmployees{
    display: flex;
    flex-direction: column;
    height: 250px;
    align-items: center;
}


.footer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #768EFB;
    color: white;

    
    
}

.footer a {
    margin: auto;
    color: white;
    text-decoration: none;
}

.footer a:hover {
    opacity: .5;
}
.footerLeft{
    grid-column-start: 1;
    margin-left: 0;
    align-items: center;
    width: 75%;
    margin: auto;
}
.footerRight{
    grid-column-start: 2;
     margin: auto;
}
footer a{
    grid-column-start: 3;
}

.employees{
    display:flex;
    padding: 2rem 0;
}

.employeesLeft{
    width: 50%;
}

.employeesRight{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.signup{
    background-color: #768EFB;
    height: 300px;
    border-radius: 10px;
    width: 70%;
}
.signup input{
    height: 1.5rem;
    margin-top: 5%;
    margin-left: 7.5%;
} 
.signup button{
    height: 3rem;
    width: 7rem;
    border-radius: 10px;
    background-color: #7FE5EA;
    margin-top: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.fileButton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 7rem;
    border-radius: 5px;
    background-color: #7FE5EA;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.browseHeader{
color: #768EFB;
margin: auto;
text-align: center;
margin-bottom: 1rem;
}
.searchBar {
    margin: 0;
    margin-bottom: 3rem;
    display: flex;
    justify-content:center;
}

.searchBar input{
    height: 1.5rem;
    width: 8rem;
    margin: 0 .5rem;
}

.searchBar select{
    margin: 0 .5rem;
    height: 1.85rem;
}

.searchBar input[type=submit]{
    background-color: #768EFB;
    height: 2rem;
    width: 4rem;
    margin: 0 1rem;
    border-radius: 5px;
    color: white;
    border: 1px solid #768EFB;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.searchBar input[type=submit]:active {
    box-shadow: none;
    opacity: .7;
}

.searchForm {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #7FE5EA;
    width: auto;
    height: 4rem;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.jobListing {
    background-color: #768EFB;
    height: auto;
    width: 60%;
    margin: auto;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.jobListingDescriptionDiv{
    background-color: #768EFB;
    height: 8rem;
    width: 60%;
    margin: auto;
    border-radius: 10px;
    display: block;
    flex-wrap: wrap;
    margin-bottom: 2.5rem;
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
}
.jobListingDescription{
    width: 40rem;
    text-align: center;
    
}
.jobListingHeader{
    margin: auto;
    text-align: center;
    text-decoration: underline;
    font-size: .8rem;
}
.jobListing .placeHolderImage{
    height: 3rem;
    margin: auto;
}

.listing-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25rem;
}

.listing-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: .5rem;
}

.jobSubmit{
    grid-column-start: 4;
    margin-left: 2rem;
    height: 4rem;
    width: 9rem;
    background-color: #7FE5EA;
    margin: auto;
    border-radius: 10px;
    line-height: 1.2rem;
    text-align: center;
    margin: auto;
    color: white;
    font-size: 1.2rem;
    font-weight: bold
}

textarea #description {
    white-space: pre-line;
}

.container{
    margin-left: 5rem;
    margin-right: 5rem;
}
.aboutUs{
height: 71.5vh;
}

.aboutUs h1{
    text-align: center;
    color: #768EFB
}

/* FAQ Sections */

.faq {
    margin-bottom: 5rem;
}

.faq-header {
    text-align: center;
    color: #768EFB;
}

.faq h2 {
    text-decoration: underline;
}

/* Profile Page w/info */

.profile-logout button {
    background-color: #7FE5EA;
    border: 1px solid #7FE5EA;
    border-radius: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 5rem;
    height: 2rem;
    margin-top: 3rem;
}

.profile-logout button a {
    text-decoration: none;
    color: white;
}

.profile-logout button:hover {
    opacity: .7;
}

.profile-container {
    text-align: center;
    margin-bottom: 4rem;
}

.profile-container p a{
    text-decoration: none;
    color: #768EFB;
}

.profile-container p a:hover{
    opacity: .7;
}

/* buttons */
.actions {
   margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
.actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #7FE5EA;
    border: 1px solid #7FE5EA;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
}
  
.actions button:active {
    opacity: .7;
}
  
.actions .toggle {
    margin-top: 1rem;
    background-color: transparent;
    color: #7FE5EA;
    border: none;
    padding: 0.15rem 1.5rem;
}
  
.actions .toggle:hover {
    opacity: .5;
}

/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 90%;
    top: 36px;
}
  
/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #768EFB;
}
  
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    opacity: .7;
}
  
/* Position and sizing of clickable cross button */
.bm-cross-button {
    margin-right: 2rem;
    height: 24px;
    width: 24px;
    right: .8rem !important;
    top: -2rem !important;
    width: 46px !important;
    height: 39px !important;
    background-color: white;
}
  
/* Color/shape of close button cross */
.bm-cross {
    background: black;
    width: 7px !important;
    height: 30px !important;
}
  
/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    margin-top: 4rem;
    width: 100vw !important;
}
  
/* General sidebar styles */
.bm-menu {
    padding: 1.5rem 1.5rem 0;
    font-size: 1.15rem;
    overflow: visible !important;
}
  
/* Wrapper for item list */
.bm-item-list {
    color: white;
    padding: 0.8rem;
    text-align: right;
}
  
/* Individual item */
.bm-item {
    display: inline-block;
    text-decoration: none;
    color:#768EFB;
    width: 4.75rem;
    margin:auto;
    text-align: center;
}

.bm-item:hover {
      opacity: .7;
}
  
/* Styling of overlay */
.bm-overlay {
    background: white !important;
    right: 0;
}

.aboutUs p {
      width: 55%;
      margin: auto;
}

/* Cell Phones */
@media screen and (max-width: 480px) {
    .bm-menu-wrap {
        position: fixed;
        margin-top: 4rem;
        width: 100vw;
    }
    /* menu */
    .html {
        width: 100%;
    }

    .logo {
        margin-left: 0;
    }

    .hero {
        width: 100%;
        flex-shrink: 0;
        height: 15rem;
        background-image: none;
        display: none;
    }


    .homepageContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        margin-top: 3rem;
    }

    .homepageLeft {
        margin-bottom: 3em;
    }

    .homepageLeft img {
        width: 100%;
        margin: 0;
    }

    .homepageRight {
        margin: 0;
        height: auto;
    }

    .homepageRight .banner-with-links {
        height: 100%;
    }

    .competitiveJob {
        height: 50%;
    }

    .skilledEmployees {
        height: 50%;
    }

    .skilledEmployees .blueButton {
        margin-bottom: 2rem;
    }

    .blueButton {
        width: 10rem;
    }
      
    .bm-burger-button {
        left: 80%;
    }
    .container{
        margin-left: 1rem;
    }
    .jobListing {
        font-size: 1rem;
        background-color: #768EFB;
        height: auto;
        width: 80%;
        margin: auto;
        border-radius: 10px;
        display: block;
        margin-bottom: 2.5rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding-top: 1rem;
        padding-left: 1rem;
    }
        .jobListingHeader{
        display: none;
    }
    .jobListing p{
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .listing-info-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 20rem;
    }
    

    .mobileApplyButton{
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    .jobListing .placeHolderImage{
        height: 3rem;
        margin: auto;
        padding-left: 2rem;
        display: none;
    }
    /* sarchbar section */
    .searchBar input{
        height: 1.5rem;
        width: 4rem;
        margin-top: .2rem;
        border-radius: 5px;
        border: 1px solid rgb(143, 143, 143);
    }
    
    .searchBar select{
        margin-top: .2rem;
        height: 1.85rem;
        display: block;
        border: 1px solid rgb(143, 143, 143);
    }
    
    .searchBar input[type=submit]{
        background-color: #768EFB;
        margin-top: 1rem;
        height: 2rem;
        width: 4rem;
        border-radius: 5px;
        color: white;
    }
    .searchForm {
        display: flex; 
        padding: .5rem;
        flex-direction: row;
        height: auto;
        width: 75%;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .searchForm select {
        width: 4.5rem;
    }

    .footer {
        height: 8rem;
    }

    .footer p {
        font-size: 8px;
        width: 100%;
        word-wrap: break-word;
    }

    .footerRight p {
        font-size: 6px;
    }
    
    .footer h2 {
        font-size: 14px;
    }

    .container {
        margin: auto;
        margin-bottom: 3rem;
    }

    .faq {
        width: 90%;
        margin: auto;
    }
}

/* Tablets */
@media screen and (max-width: 1024px) and (min-width: 481px) {
    .homepageContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 0;
        margin-top: 3rem;
    }

    .homepageLeft {
        margin-bottom: 3rem;
    }

    .homepageLeft img {
        width: 100%;
        margin: 0;
    }

    .homepageRight {
        margin: 0;
        height: auto;
    }

    .homepageRight .banner-with-links {
        height: 100%;
    }

    .faq {
        margin-bottom: 5em;
    }
}